import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import styles from './error.module.scss';

const GenericErrorPage: React.FC = () => (
  <Layout>
    <SEO title="Uh oh, something went wrong" description="" />
    <section className={styles.section}>
      <h1>UH OH</h1>
      <p>Something went wrong, please try again</p>
    </section>
  </Layout>
);

export default GenericErrorPage;
